import classNames from '@/external/classNames';

type Props = {
  className?: string;
  onClose?: () => void;
};
const Panel: FC<Props> = ({ children, className }) => (
  <div
    data-testid="modal-panel"
    className="sticky inset-0 max-h-[95vh] overflow-y-auto"
  >
    <div className="flex min-h-full items-center justify-center p-4">
      <div
        className={classNames(
          'w-full max-w-sm inline-block transform rounded-xl bg-goku align-middle shadow-moon-lg transition-all',
          className,
        )}
      >
        {children}
      </div>
    </div>
  </div>
);

export default Panel;
